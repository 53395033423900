import React, { ReactNode, RefObject, useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

import { hexToRgba } from "base_css/utils/color"
import { rems } from "base_css/utils/fontSize"
import { breakpoint } from "base_css/vars"
import Close from "components/svg/Close"

interface IOverlay {
  pageYOffset: number
}

const SOverlay = styled.div<IOverlay>`
  align-items: center;
  background-color: ${({ theme }) => hexToRgba(theme.color.grey.dark, 0.7)};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: ${({ pageYOffset }) => `${pageYOffset}px`};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.towerBlock};
`

const SModalWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${rems(5)};
  box-shadow: 0 ${rems(5)} ${rems(20)} 0 rgba(34, 34, 34, 0.15);
  font-size: ${({ theme }) => theme.fontSize.small};
  max-height: 100%;
  min-height: 25%;
  overflow: scroll;
  padding: ${({ theme }) => theme.spacing.mediumLarge};
  position: relative;
  width: 50%;
  z-index: ${({ theme }) => theme.zIndex.skyscraper};

  ${breakpoint.mediumDown} {
    padding: ${({ theme }) =>
      `${theme.spacing.mediumLarge} ${theme.spacing.medium} ${theme.spacing.mediumLarge} ${theme.spacing.medium}`};
    text-align: left;
    width: 90%;
  }
`

const SCloseWrapper = styled.button`
  position: absolute;
  right: ${rems(10)};
  top: ${rems(10)};

  svg {
    :hover {
      fill: ${({ theme }) => theme.color.accent};
    }
  }
`

interface IModal {
  children?: ReactNode
  isVisible: boolean
  hideModal: () => void
}

const Modal = ({ children, isVisible, hideModal }: IModal) => {
  const modalOverlayRef = useRef<HTMLDivElement>(null)
  const modalWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isVisible) {
      modalOverlayRef.current?.addEventListener("click", backDropHandler)
      document.body.style.overflow = "hidden"
      return () => {
        document.body.style.overflow = "scroll"
        modalOverlayRef.current?.removeEventListener("click", backDropHandler)
      }
    }
  }, [isVisible])

  const backDropHandler = (event: MouseEvent) => {
    if (!modalWrapperRef?.current?.contains(event.target as Node)) {
      hideModal()
    }
  }

  return isVisible
    ? createPortal(
        (
          <SOverlay
            ref={modalOverlayRef as RefObject<HTMLDivElement>}
            pageYOffset={window.pageYOffset}
          >
            <SModalWrapper ref={modalWrapperRef as RefObject<HTMLDivElement>}>
              <SCloseWrapper onClick={hideModal}>
                <Close />
              </SCloseWrapper>
              {children}
            </SModalWrapper>
          </SOverlay> // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any,
        document.getElementById("modal-root") as HTMLElement,
      )
    : null
}

export default Modal
