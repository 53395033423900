import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface IBulletList {
  className?: string
  list: string[] | React.ReactElement[]
}

const SP = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.xSmall};
`

const SContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xSmall};
`

const SUl = styled.ul`
  list-style: none;
  padding-left: ${({ theme }) => theme.spacing.small};
`

const SLi = styled.li`
  display: flex;

  &::before {
    color: ${({ theme }) => theme.color.accent};
    content: "•";
    margin-right: ${rems(8)};
  }
`

const BulletList = ({ className, list }: IBulletList) => {
  return (
    <SUl className={className}>
      {list.map((item, index) => (
        <SLi key={index}>
          {typeof item === "string" ? (
            <SP>{item}</SP>
          ) : (
            <SContainer>{item}</SContainer>
          )}
        </SLi>
      ))}
    </SUl>
  )
}

export default BulletList
