import React, { useState } from "react"
import styled from "styled-components"

import { h4, h5 } from "base_css/mixins"
import { hexToRgba } from "base_css/utils/color"
import { rems } from "base_css/utils/fontSize"
import Breakpoint from "components/atoms/Breakpoint"
import { InlineCustomLink } from "components/atoms/CustomLink"
import { Cell, Grid } from "components/atoms/Grid"
import InfoCircle from "components/svg/InfoCircle"
import ClickTracker from "features/analytics/ClickTracker"
import * as ga from "features/analytics/ga"

export type CalculatorOrientation = "horizontal" | "vertical"

interface ICalculatorStyle {
  orientation?: CalculatorOrientation
}

const SCalculator = styled.div<ICalculatorStyle>`
  background-image: linear-gradient(
    ${({ theme }) => hexToRgba(theme.color.primary, 1)} 0%,
    ${({ theme }) => hexToRgba(theme.color.primary, 0.8)} 100%
  );
  border-radius: ${rems(5)};
  display: flex;
  flex-direction: ${({ orientation }) =>
    orientation === "vertical" ? "column" : "row"};
  height: 100%;
  padding: ${({ theme }) => theme.spacing.medium};
  text-align: left;
  width: 100%;
`

const SGrid = styled(Grid)`
  column-gap: ${({ theme }) => theme.spacing.medium};
`

const STitle = styled.h3`
  ${h5} /* stylelint-disable-next-line */
  color: ${({ theme }) => theme.color.white};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  text-align: left;
`

const SSubtitle = styled.span`
  color: ${({ theme }) => theme.color.grey.light};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-bottom: ${({ theme }) => theme.spacing.mediumSmall};
  text-align: left;
`

const SCalculatorContent = styled.div`
  & > *:last-child {
    margin-bottom: 0;
  }
`

export const SCalcInputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const SAnswerWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

const SAnswerCard = styled.div`
  align-items: center;
  background-color: ${({ theme }) => hexToRgba(theme.color.primary, 0.6)};
  border-radius: ${rems(5)};
  box-shadow: 0 ${rems(2)} ${rems(6)} 0 rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.mediumSmall};
  position: relative;
  text-align: center;
  width: 100%;
`

const SAnswer = styled.div`
  ${h4} /* stylelint-disable-next-line */
  margin: 0;
`

interface ICtaTextStyle {
  size?: "small" | "medium"
}

const SCtaText = styled.div<ICtaTextStyle>`
  font-size: ${({ size = "small", theme }) =>
    size === "small" ? theme.fontSize.xSmall : theme.fontSize.small};
  font-weight: bold;
  margin-top: ${({ theme }) => theme.spacing.xSmall};
  opacity: 90%;
`

const SInlineCustomLink = styled(InlineCustomLink)`
  text-shadow: 2px 2px 4px ${({ theme }) => hexToRgba(theme.color.primary, 0.6)};
`

const SInfoWrapper = styled.div`
  bottom: ${({ theme }) => theme.spacing.xSmall};
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: ${({ theme }) => theme.spacing.xSmall};
`

interface ICalculator extends ICalculatorStyle {
  analyticsID: string
  title: string
  subtitle: string
  children: React.ReactNode
  answerText: string
  answerValue?: string
  showModal?: () => void
}

const Calculator = ({
  analyticsID,
  title,
  subtitle,
  children,
  answerText,
  answerValue,
  orientation = "vertical",
  showModal,
}: ICalculator) => {
  const [hasUserEngaged, setHasUserEngaged] = useState<boolean>(false)

  const sendCalculatorEngagementEvent = () => {
    if (!hasUserEngaged) {
      setHasUserEngaged(true)
      ga.calculatorEngagementEvent(analyticsID)
    }
  }

  const placeholderText = "Please enter your details"
  const verticalCalculator = (
    <SCalculator orientation="vertical">
      <STitle>{title}</STitle>
      <SSubtitle>{subtitle}</SSubtitle>
      <div onClick={sendCalculatorEngagementEvent}>{children}</div>
      <SAnswerCard>
        {answerValue ? (
          <>
            {answerText}
            <SAnswer>{answerValue}</SAnswer>
            {showModal && (
              <SInfoWrapper onClick={showModal}>
                <InfoCircle
                  variant="light"
                  analyticsID="Calculator InfoCircle"
                />
              </SInfoWrapper>
            )}
            <SCtaText>
              Ready to{" "}
              <SInlineCustomLink
                href="/get-started"
                analyticsID="Calculator GetStartedLink"
              >
                get started?
              </SInlineCustomLink>
            </SCtaText>
          </>
        ) : (
          <strong>{placeholderText}</strong>
        )}
      </SAnswerCard>
    </SCalculator>
  )
  const horizontalCalculator = (
    <SCalculator orientation="horizontal">
      <SGrid>
        <Cell width={7} left={1}>
          <STitle>{title}</STitle>
          <SSubtitle>{subtitle}</SSubtitle>
          <SCalculatorContent onClick={sendCalculatorEngagementEvent}>
            {children}
          </SCalculatorContent>
        </Cell>
        <Cell width={5} left={8}>
          <SAnswerWrapper>
            <SAnswerCard>
              {answerValue ? (
                <>
                  {answerText}
                  <SAnswer>{answerValue}</SAnswer>
                  {showModal && (
                    <ClickTracker analyticsID={"Calculator InfoCircle"}>
                      <SInfoWrapper onClick={showModal}>
                        <InfoCircle
                          variant="light"
                          analyticsID="Calculator InfoCircle"
                        />
                      </SInfoWrapper>
                    </ClickTracker>
                  )}
                  <SCtaText size="medium">
                    Ready to{" "}
                    <SInlineCustomLink
                      href="/get-started"
                      analyticsID="Calculator GetStartedLink"
                    >
                      get started?
                    </SInlineCustomLink>
                  </SCtaText>
                </>
              ) : (
                <strong>{placeholderText}</strong>
              )}
            </SAnswerCard>
          </SAnswerWrapper>
        </Cell>
      </SGrid>
    </SCalculator>
  )
  return orientation === "vertical" ? (
    verticalCalculator
  ) : (
    <>
      <Breakpoint showFor="large">{horizontalCalculator}</Breakpoint>
      <Breakpoint showFor="mediumDown">{verticalCalculator}</Breakpoint>
    </>
  )
}

export default Calculator
