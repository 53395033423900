import React from "react"
import styled from "styled-components"

import { rems } from "base_css/utils/fontSize"

interface IClose {
  className?: string
}

const SClose = styled.svg<IClose>`
  fill: ${({ theme }) => theme.color.grey.dark};
  width: ${rems(20)};
`

const Close = ({ className }: IClose) => (
  <SClose className={className} viewBox="0 0 46 46">
    <g>
      <path d="M 39.3044 33.6081 L 28.7961 23.1071 L 39.3014 12.6082 C 40.8641 11.0464 40.8641 8.5149 39.3014 6.9536 C 37.7387 5.3928 35.2052 5.3928 33.6444 6.9536 L 23.1387 17.4531 L 12.6324 6.9536 C 11.0707 5.3928 8.5376 5.3923 6.9749 6.9541 C 5.4127 8.5154 5.4127 11.0469 6.9754 12.6082 L 17.4817 23.1071 L 6.9749 33.6081 C 5.4127 35.1689 5.4127 37.7009 6.9749 39.2616 C 7.7563 40.0425 8.7798 40.433 9.8039 40.433 C 10.828 40.433 11.8515 40.0425 12.6329 39.2616 L 23.1396 28.7607 L 33.6474 39.2616 C 34.4287 40.0425 35.4523 40.433 36.4759 40.433 C 37.4994 40.433 38.524 40.0425 39.3044 39.2616 C 40.8671 37.7009 40.8671 35.1689 39.3044 33.6081 Z" />
    </g>
  </SClose>
)

export default Close
